<template>
  <div>
    <el-dialog :close-on-click-modal="false" :show-close="false" :visible.sync="dialogVisible" width="70%"
      @close="handleClose">
      <el-card>
        <p class="title">{{ title }}</p>
        <el-form ref="form" :rules="rules" :model="form" label-width="120px" id="selectForm">
          <el-row>
            <el-col :span="12">
              <el-form-item label="产品名称:" prop="productName">
                <el-input :maxlength="50" :disabled="onlyLook" show-word-limit v-model="form.productName"
                  placeholder="请输入产品名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="产品大类:" prop="catalogCode">
                <el-select v-model="form.catalogCode" :disabled="onlyLook" @change="changeSelect" placeholder="请选择产品大类">
                  <el-option v-for="(item, index) in productList" :key="index" :label="item.dictionaryName"
                    :value="item.dictionaryCode"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="状态:" prop="onsaleStatus">
                <el-select v-model="form.onsaleStatus" :disabled="onlyLook" placeholder="请选择产品状态">
                  <el-option label="上架" value="1"></el-option>
                  <el-option label="下架" value="-1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="归属机构:" prop="deptId">
                <el-select v-model="form.deptId" @change="changeJiGou" :disabled="onlyLook" placeholder="请选择机构">
                  <el-option v-for="(item, index) in companyList" :key="index" :label="item.companyName"
                    :value="item.companyId"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="服务期限:" prop="timeLimit">
                <el-input @input.native="inputhandle" :disabled="onlyLook" :maxlength="3" v-model.number="form.timeLimit"
                  placeholder="单位：月"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否可支付:" prop="onlineSale">
                <el-select :disabled="onlyLook" v-model="form.onlineSale" placeholder="产品是否可在线支付">
                  <el-option label="是" value="1"></el-option>
                  <el-option label="否" value="-1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="微盟产品ID:" prop="microneId">
                <el-input maxlength="20" :disabled="onlyLook" show-word-limit v-model="form.microneId"
                  placeholder="请输入微盟产品ID"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="微盟产品链接:" prop="productUrl">
                <el-input maxlength="100" :disabled="onlyLook" show-word-limit v-model="form.productUrl"
                  placeholder="请输入微盟产品链接"></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
              <el-form-item label="服务次数:" prop="serviceNum">
                <el-input maxlength="20" type="number" :disabled="onlyLook" show-word-limit v-model="form.serviceNum" placeholder="请输入服务次数"></el-input>
              </el-form-item>
            </el-col> -->
            <el-col :span="24">
              <el-form-item label="升级服务介绍:" prop="upgradeService">
                <el-input :disabled="onlyLook" v-model="form.upgradeService" placeholder="请输入升级服务介绍"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="上下架日期:" prop="value1">
                <el-date-picker :default-time="['00:00:00', '23:59:59']" value-format="yyyy-MM-dd HH:mm:ss"
                  v-model="form.value1" :disabled="onlyLook" @input="changeDate123" type="daterange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
              <!-- <el-form-item label="开始日期" prop="startDate">
              <el-date-picker
                @change="changeStartDate"
                :disabled="onlyLook"
                v-model="form.startDate"
                value-format="yyyy-MM-dd 00:00:00"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item> -->

            </el-col>
            <!-- <el-col :span="12">
            <el-form-item label="结束日期">
              <el-date-picker
                v-model="form.endDate"
                :disabled="onlyLook"
                value-format="yyyy-MM-dd 23:59:59"
                type="date"
                :picker-options="expireTimeOption"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col> -->

            <el-col :span="24">
              <el-form-item label="服务协议:">
                <el-upload v-if="!onlyLook || form.serviceAgreement.length != 0"
                  :class="{ hideUpload: form.serviceAgreement.length > 0 }" :action="fileUrl" :disabled="onlyLook"
                  :before-upload="fileBeforeAvatarUpload"
                  :on-remove="(file, fileList) => { return fileRemove(file, fileList, 'serviceAgreement') }"
                  :on-success="(file) => { return fileSuccess(file, 'serviceAgreement') }"
                  :file-list="form.serviceAgreement" :on-preview="previewFile">
                  <div class="uploadBtnBox">
                    <el-button type="primary">点击上传</el-button>
                    <div slot="tip" class="el-upload__tip prompt">只能上传word文件</div>
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-if="form.catalogCode == 'TJ'">
              <el-form-item label="隐私保护协议:">
                <el-upload v-if="!onlyLook || form.privacyProtectionAgreement.length != 0"
                  :class="{ hideUpload: form.privacyProtectionAgreement.length > 0 }" :disabled="onlyLook"
                  :action="fileUrl" :before-upload="fileBeforeAvatarUpload"
                  :on-remove="(file, fileList) => { return fileRemove(file, fileList, 'privacyProtectionAgreement') }"
                  :on-success="(file) => { return fileSuccess(file, 'privacyProtectionAgreement') }"
                  :file-list="form.privacyProtectionAgreement" :on-preview="previewFile">
                  <div class="uploadBtnBox">
                    <el-button type="primary">点击上传</el-button>
                    <div slot="tip" class="el-upload__tip prompt">只能上传word文件</div>
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col>
              <div v-if="['HJ', 'JC'].includes(form.catalogCode)" id="addMore">
                <el-form-item label="产品权益:">
                  <div class="inner">
                    <div style="height: 51px"></div>
                  </div>
                  <div class="inner">
                    <div v-for="(items, index) in form.productRigthVoList" :key="index" id="add">
                      <el-form-item label-width="80px" label="权益名称:" prop="rightsId">
                        <el-select v-model="items.rightsId" @change="changePrduct($event, index)" :disabled="onlyLook"
                          placeholder="请选择产品权益">
                          <el-option v-for="item in rightsList" :key="item.dictionaryId" :label="item.dictionaryName"
                            :value="item.dictionaryId"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label-width="60px" label="配额:" prop="quota">
                        <el-input placeholder="请输入产品配额" type="number" :disabled="onlyLook" @mousewheel.native.prevent
                          @keyup.native="prevent($event)" min="1" v-model.number="items.quota"></el-input>
                      </el-form-item>
                      <el-form-item label-width="70px" label="内外部:" prop="insideOrOutside">
                        <el-select v-model="items.insideOrOutside" :disabled="onlyLook" placeholder="内外部产品">
                          <el-option label="内部权益" value="0"></el-option>
                          <el-option label="外部权益" value="1"></el-option>
                        </el-select>
                      </el-form-item>
                      <span class="delButton" v-if="!onlyLook" @click="deleteLine(index)">删除</span>
                    </div>
                  </div>
                  <el-button @click="handleAdd" class="add" v-if="!onlyLook" icon="el-icon-plus">新增产品权益</el-button>
                </el-form-item>
              </div>
            </el-col>
            <el-col>
              <el-form-item>
                <div class="inner-1">
                  <el-form-item label="上传封面:" label-width="80px">
                    <el-upload :class="imageUrl ? '' : 'avatar-uploader'" :disabled="onlyLook" :action="action"
                      :show-file-list="false" :before-upload="beforeAvatarUpload" :on-success="handleAvatarSuccess">
                      <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                  </el-form-item>
                  <el-form-item label="产品介绍:" label-width="78px">
                    <el-upload :class="imageUrl1 ? '' : 'avatar-uploader'" :disabled="onlyLook" :action="action"
                      :show-file-list="false" :before-upload="beforeAvatarUpload1" :on-success="handleAvatarSuccess1">
                      <img v-if="imageUrl1" :src="imageUrl1" class="avatar" />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                  </el-form-item>
                  <el-form-item label="产品图标:" label-width="78px">
                    <el-upload :class="imageUrl2 ? '' : 'avatar-uploader'" :disabled="onlyLook" :action="action"
                      :show-file-list="false" :before-upload="beforeAvatarUpload2" :on-success="handleAvatarSuccess2">
                      <img v-if="imageUrl2" :src="imageUrl2" class="avatar" />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                  </el-form-item>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureSubmit('form')">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisiblesee" width="40%" class="Visiblesee" fullscreen
      style="text-align: center; background-color: red">
      <iframe :src="previewUrl" alt="" frameborder="0" style="width: 70%; height: 860px" scrolling="no" />
    </el-dialog>
  </div>
</template>

<script>
import { postRequest } from 'src/api/index'
import { imgaction, fileuploadaction } from 'src/utils/cities'
export default {
  data() {
    return {
      currentPage: 0, // pdf文件页码
      pageCount: 0, // pdf文件总页数
      fileType: 'pdf', // 文件类型
      dialogVisible: true,
      dialogVisiblesee: false,
      companyList: [],
      imageUrl: '',
      title: '新增产品',
      productList: [],
      imageUrl1: '',
      imageUrl2: '',
      fileName: '',
      previewUrl: '',
      vuePdf: '',
      action: imgaction,
      fileUrl: fileuploadaction,
      rightsList: [],
      isShow: true,
      isChange123: false,
      isViewPDF: false,
      form: {
        serviceNum: '',
        microneId: '',
        productName: '',
        catalogName: '',
        price: '',
        introduction: '',
        onlineSale: '',
        upgradeService: '',
        deptId: '',
        startDate: '',
        endDate: '',
        onsaleStatus: '',
        indexPicUrl: '',
        serviceAgreement: [],
        privacyProtectionAgreement: [],
        productRigthVoList: [
          {
            quota: '',
            rightsId: '',
            insideOrOutside: '',
          },
        ],
      },
      rules: {
        productName: [{ required: true, message: '请输入产品名称' }],
        catalogCode: [{ required: true, message: '请选择产品大类' }],
        status: [{ required: true, message: '请选择产品大类' }],
        onsaleStatus: [{ required: true, message: '请选择产品状态' }],
        deptId: [{ required: true, message: '请选择归属机构' }],
        upgradeService: [{ required: true, message: '请输入升级服务介绍' }],
        onlineSale: [{ required: true, message: '请选择是否可在线服务' }],
        timeLimit: [{ required: true, message: '请输入服务期限' }],
        startDate: [{ required: true, message: '请选择服务开始时间', trigger: 'change' }],
        value1: [{ required: true, message: '请选择上下架时间' }],
        microneId:[{required:true,message:'请输入微盟产品ID'}]
      },
      expireTimeOption: {
        disabledDate: date => {
          return date.getTime() < new Date(this.form.startDate)
        },
      },
      detection: []
    }
  },
  computed: {
    isChange() {
      return this.onlyLook || this.isChange123
    },
  },
  props: ['info', 'onlyLook'],
  created() {
    this.vuePdf = require('vue-pdf')
  },
  mounted() {
    console.log(123, this.info)
    // if (this.onlyLook) {
    //   this.isChange = true
    // } else {
    //   this.isChange = false
    // }
    if (this.info != '') {
      this.isChange123 = true
      if (this.info.catalogCode == 'HJ') {
        this.isShow = true
      }
      if (this.info.catalogCode == 'DP') {
        this.isShow = false
      }
      this.info.value1 = [this.info.startDate, this.info.endDate]
      this.imageUrl = this.info.indexPicUrl
      this.imageUrl1 = this.info.introduction
      this.imageUrl2 = this.info.iconUrl
      this.form = this.info
      this.form.catalogName = this.info.catalogName
      if (this.onlyLook) {
        this.title = '查看'
      } else {
        this.title = '修改产品'
      }
    if (this.form.catalogCode == 'JC') {
      postRequest('/dict/getDictByCategory', { dictionaryCategoryCode: '15' }).then(res => {
        console.log(res, '权益')
        this.rightsList = res
      })
    }else {
      postRequest('/dict/getDictByCategory', { dictionaryCategoryCode: '05' }).then(res => {
        console.log(res, '权益')
        this.rightsList = res
      })
    }

    }
    postRequest('/company/queryCompany', {}).then(res => {
      console.log(res, '机构列表')
      this.companyList = res
    })
    postRequest('/dict/getDictByCategory', { dictionaryCategoryCode: '04' }).then(res => {
      console.log(res, '产品小类')
      this.productList = res
    })
    if (this.form.catalogCode == 'JC') {
      postRequest('/dict/getDictByCategory', { dictionaryCategoryCode: '15', stuts: 1 }).then(res => {
        console.log(res, '权益')
        this.rightsList = res
      })
    } else {
      postRequest('/dict/getDictByCategory', { dictionaryCategoryCode: '05', stuts: 1 }).then(res => {
        console.log(res, '权益')
        this.rightsList = res
      })
    }

  },
  methods: {
    changePrduct(val, i) {
      if (this.form.catalogCode == 'JC') {
        let prduct = this.rightsList.find(v => v.dictionaryId == this.form.productRigthVoList[i].rightsId)
        this.form.productRigthVoList[i].dicCode = prduct.dictionaryCode
      }
    },
    previewFile(file) {
      this.$confirm(`是否预览 ${file.name}？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        // type: 'warning',
      })
        .then(() => {
          // this.previewUrl = file.url
          // this.dialogVisiblesee = true
          window.open('https://view.officeapps.live.com/op/view.aspx?src=' + file.url)
        })
        .catch(() => { })
    },
    fileRemove(file, fileList, key) {
      this.form[key] = fileList
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    fileBeforeAvatarUpload(file) {
      console.log(file.type)
      const isPDF =
        file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        file.type === 'application/msword'
      if (!isPDF) {
        this.$message.error('上传文件只能是 word 格式!')
      } else {
        this.fileName = file.name
      }
      return isPDF
    },
    fileSuccess(file, key) {
      this.form[key].push({ name: this.fileName, url: file.data })
    },
    handleClose() {
      this.$emit('closeEdit')
    },
    prevent(e) {
      var keynum = window.event ? e.keyCode : e.which //获取键盘码
      if (keynum == 189 || keynum == 190 || keynum == 109 || keynum == 110) {
        this.$message.warning('禁止输入小数以及负数')
        e.target.value = ''
      }
    },
    changeJiGou(val) {
      this.companyList.map(item => {
        if (item.companyId == val) {
          this.form.deptName = item.companyName
        }
      })
    },
    inputhandle({ target }) {
      this.verificationVal = target.value = target.value.replace(/[^0-9]/g, '')
    },
    changeStartDate() {
      this.$forceUpdate()
      this.form.endDate = ''
    },
    deleteLine(i) {
      console.log(i)
      console.log(this.form.productRigthVoList)
      this.form.productRigthVoList.splice(i, 1)
    },
    changeDate123(val) {
      console.log(val)
      console.log(123)
      this.$forceUpdate()
      console.log(val)
      this.form.startDate = val[0]
      this.form.endDate = val[1]
    },
    sureSubmit(form) {
      if (this.onlyLook) {
        this.handleClose()
      } else {
        this.$refs[form].validate(valid => {
          if (valid) {
            for (let i = this.form.productRigthVoList.length - 1; i >= 0; i--) {
              if (
                this.form.productRigthVoList[i].rightsId == '' ||
                this.form.productRigthVoList[i].insideOrOutside == '' ||
                this.form.productRigthVoList[i].quota == ''
              ) {
                this.form.productRigthVoList.splice(i, 1)
              }
            }
            if (this.info != '') {
              this.form.id = this.info.id
              postRequest('/product/update/', this.form).then(res => {
                this.$message({
                  message: '修改成功',
                  type: 'success',
                })
                this.handleClose()
              })
            } else {
              postRequest('/product/addSave/', this.form).then(res => {
                console.log(res)
                this.$message({
                  message: '添加成功',
                  type: 'success',
                })
                this.handleClose()
              })
            }
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    handleAvatarSuccess(res, file) {
      console.log(res, 'res', file, 'file')
      if (res.code == 200) {
        this.$message({
          message: '图片上传成功',
          type: 'success',
        })
        this.form.indexPicUrl = res.data
        this.imageUrl = URL.createObjectURL(file.raw)
      }
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传封面图片大小不能超过 2MB!')
      }
      return isLt2M
    },
    beforeAvatarUpload1(file) {
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        this.$message.error('上传产品介绍图片大小不能超过 5MB!')
      }
      return isLt2M
    },
    beforeAvatarUpload2(file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传产品图标大小不能超过 2MB!')
      }
      return isLt2M
    },
    handleAvatarSuccess1(res, file) {
      console.log(res, 'res', file, 'file')
      if (res.code == 200) {
        this.$message({
          message: '图片上传成功',
          type: 'success',
        })
        this.form.introduction = res.data
        this.imageUrl1 = URL.createObjectURL(file.raw)
      }
    },
    handleAvatarSuccess2(res, file) {
      console.log(res, 'res', file, 'file')
      if (res.code == 200) {
        this.$message({
          message: '图片上传成功',
          type: 'success',
        })
        this.form.iconUrl = res.data
        this.imageUrl2 = URL.createObjectURL(file.raw)
      }
    },
    async changeSelect(val) {
      console.log(val, '变更大类')
      console.log(this.form.catalogName, 'mc')
      this.rightsList = []
      this.form.productRigthVoList = [{
        quota: '',
        rightsId: '',
        insideOrOutside: '',
      }]
      if (val == 'DP') {
        this.isShow = false
      }
      if (val == 'HJ') {
        this.isShow = true
      }
      if (val == 'JC') {
        // 检测权益列表 15
        let res = await postRequest('/dict/getDictByCategory', { dictionaryCategoryCode: '15', stuts: 1 })
        this.rightsList = res
      } else {
        let res = await postRequest('/dict/getDictByCategory', { dictionaryCategoryCode: '05', stuts: 1 })
        this.rightsList = res
      }
      this.productList.map(item => {
        if (item.dictionaryId == val) {
          this.form.catalogName = item.dictionaryName
        }
      })
    },
    // beforeAvatarUpload(file) {
    //   const isJPG = file.type === 'image/jpeg';
    //   const isLt2M = file.size / 1024 / 1024 < 2;

    //   if (!isJPG) {
    //     this.$message.error('上传图片只能是 JPG 格式!');
    //   }
    //   if (!isLt2M) {
    //     this.$message.error('上传图片大小不能超过 2MB!');
    //   }
    //   return isJPG && isLt2M;
    // },
    handleAdd() {
      let a = {
        quota: '',
        rightsId: '',
        productId: this.info.id,
      }
      this.form.productRigthVoList.unshift(a)
    },
  },
}
</script>
<style lang="scss">
.hideUpload {
  .el-upload {
    display: none;
    /* 上传按钮隐藏 */
  }

  .el-upload-list {
    .el-upload-list__item:first-child {
      margin-top: 3.3px;
    }
  }
}
</style>
<style lang="scss" scoped>
.el-select {
  width: 100% !important;
}

.avatar-uploader .el-upload {
  border: 1px solid #999 !important;
}

#addMore {
  .addMore-1 {
    width: 100%;

    p {
      width: 120px;
      text-align: right;
    }
  }

  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  .add {
    width: 100%;
    display: inline-block;
    font-size: 14px;
    text-align: center;
    background: #eff2fa;
    color: #5479ff;
    margin-bottom: 20px;
  }
}

.avatar-uploader {
  border: 1px dashed #5479ff !important;
  border-radius: 5px;
}

.avatar-uploader .el-upload {
  border: 1px solid #5479ff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #5479ff;
  width: 146px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 146px;
  height: 178px;
  display: block;
}

.addAndReduce {
  font-size: 16px;

  :last-child {
    margin-left: 10px;
  }
}

.delButton {
  display: inline-block;
  width: 40px;
  text-align: center;
  font-size: 14px;
  color: #e95a52;
  margin-left: 19px;
}

.el-range-editor {
  width: 100%;
}

// .el-dialog {
//   .el-dialog__body {
//     margin-top: -30px;
//   }
// }
#add {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.title {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #444444;
  margin: 0 0 20px 0;
}

.inner-1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.el-card {
  max-height: 772px !important;
  overflow-x: hidden !important;
  overflow: auto !important;
  padding: -5px -2px !important;
  margin-top: -20px;
}

.el-date-editor {
  width: 100%;
}

.uploadBtnBox {
  display: flex;

  .prompt {
    margin-left: 20px;
  }
}

a {
  text-decoration: none;
}
</style>
