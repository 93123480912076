<template>
  <div class="organ-management-page">
    <el-row>
      <el-col :span="24">
        <el-card shadow="always">
          <el-form :inline="true" :model="searchFrom" class="demo-form-inline searchFrom" size="small">
            <el-form-item label="产品名称">
              <el-input v-model="searchFrom.organizationName" :clearable="true" placeholder="请输入产品名称"></el-input>
            </el-form-item>
            <el-form-item label="产品类型">
              <el-select clearable v-model="searchFrom.organizationCode" placeholder="请选择产品类型">
                <el-option label="全部 " value=""></el-option>
                <el-option v-for="item in productList" :key="item.dictionaryId" :label="item.dictionaryName" :value="item.dictionaryCode"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开始时间:">
              <el-date-picker type="date" value-format="yyyy-MM-dd" format="yyyy-MM-dd" :picker-options="pickerOptionsStart" placeholder="请选择开始时间" v-model="searchFrom.date1" style="width: 100%"></el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间:">
              <el-date-picker type="date" value-format="yyyy-MM-dd" format="yyyy-MM-dd" :picker-options="pickerOptionsEnd" placeholder="请选择结束时间" v-model="searchFrom.date2" style="width: 100%"></el-date-picker>
            </el-form-item>
            <el-form-item label="归属机构:">
              <el-select clearable v-model="searchFrom.state" placeholder="请选择机构">
                <el-option label="全部 " value=""></el-option>
                <el-option v-for="item in companyList" :key="item.companyId" :label="item.companyName" :value="item.companyId"></el-option>
              </el-select>
            </el-form-item>
            <el-button type="primary" icon="el-icon-search" :disabled="disabledSearch" @click="getAll(1)">查询</el-button>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <el-card class="card" shadow="always">
      <div>
        <el-button type="primary" plain icon="el-icon-plus" @click="add">添加</el-button>
        <el-button type="danger" plain icon="el-icon-delete" @click="deleteProduct">删除</el-button>
        <el-button type="primary" plain icon="el-icon-setting" @click="updateProduct">修改</el-button>
      </div>
      <div class="table-block">
        <div class="Selectnum">已选中 <span style="color:#5479FF">{{multipleSelection.length}}</span> 条信息</div>
        <el-table :data="tableData" ref="multipleTable" @row-click="handleClickTableRow" v-loading="loading" :header-cell-style="{ 'text-align': 'center', background: '#EEF1FC', color: '#656565' }"
          :cell-style="{ 'text-align': 'center' }" style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" fixed> </el-table-column>
          <el-table-column prop="productName" label="产品名称"> </el-table-column>
          <el-table-column prop="rightsInfo" label="产品权益"> </el-table-column>
          <el-table-column prop="companyName" label="归属机构"> </el-table-column>
          <el-table-column prop="isOK" label="状态" width="60px">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.onsaleStatus" active-color="#5479FF" inactive-color="#D5D5D5" active-value="1" @change="changeStatus(scope.row)" inactive-value="-1">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="startTime" width="120" label="上架时间"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button @click="lookFor(scope.row)" type="text">查看</el-button>
              <!-- <el-button v-if="scope.row.status == '1'" @click="handleClick(scope.row)" type="text" size="small">禁用</el-button>
              <el-button v-if="scope.row.status == '-1'" @click="handleClick(scope.row)" type="text" size="small">启用</el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[10, 20, 30, 40]" :page-size="10" layout="total, sizes, prev, pager, next, jumper" :total="total" background>
        </el-pagination>
        <edit :info="info" :onlyLook="onlyLook" v-if="showEdit" @closeEdit="closeEdit"></edit>
      </div>
    </el-card>
  </div>
</template>
<script>
import pagination from 'src/components/pagination'
import organzitionTree from 'src/components/organzitionTree.vue'
import edit from 'src/views/productManagement/component/edit'
import { getAllInstitutionsInfo, postRequest } from 'src/api/index'

export default {
  name: 'organ-management',
  components: { pagination, organzitionTree, edit },
  data() {
    return {
      searchFrom: {
        organizationName: '',
        organizationCode: '',
        date1: '',
        date2: '',
        state: '',
      },
      tableData: [],
      multipleSelection: [],
      showEdit: false,
      list: [],
      disabledSearch: false,
      productList: [],
      companyList: [],
      rightsList: [],
      loading: true,
      onlyLook: '',
      pageSize: 10,
      pageNo: 1,
      total: null,
      pickerOptionsStart: {
        disabledDate: time => {
          if (this.searchFrom.date2) {
            return time.getTime() > new Date(this.searchFrom.date2).getTime()
          }
        },
      },
      pickerOptionsEnd: {
        disabledDate: time => {
          if (this.searchFrom.date1) {
            return time.getTime() < new Date(this.searchFrom.date1).getTime() - 86400000
          }
        },
      },
    }
  },
  watch: {},
  computed: {},
  mounted() {
    // 查询所有机构
    postRequest('/company/queryCompany', {}).then(res => {
      console.log(res)
      this.companyList = res
    })
    // 查询所有产品
    this.getAll()
    // 查询产品大类
    postRequest('/dict/getDictByCategory', { dictionaryCategoryCode: '04' }).then(res => {
      console.log(res, '会籍、单品')
      this.productList = res
    })
    // 权益列表
    postRequest('/dict/getDictByCategory', { dictionaryCategoryCode: '05' }).then(res => {
      console.log(res, '权益')
      this.rightsList = res
    })
  },
  methods: {
    // 点击行选中
    handleClickTableRow(row) {
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    // 选中项
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    add() {
      this.showEdit = true
      this.info = ''
      this.onlyLook = false
    },
    changeStatus(val) {
      console.log(val)
      let data = {
        id: val.id,
        onsaleStatus: val.onsaleStatus,
      }
      postRequest('/product/update', data).then(res => {
        console.log(res, '修改结果')
        this.$message({
          message: '状态修改成功',
          type: 'success',
        })
        // this.getAll()
      })
    },
    closeEdit() {
      this.showEdit = false
      this.getAll()
    },
    // selectStart(){
    //   if (this.searchFrom.date1) {
    //     this.searchFrom.date1 = this.searchFrom.date1.getFullYear() + '-' + (this.searchFrom.date1.getMonth() + 1) + '-' + this.searchFrom.date1.getDate()
    //   }
    // },
    // selectEnd(){
    //   if (this.searchFrom.date2) {
    //     this.searchFrom.date2 = this.searchFrom.date2.getFullYear() + '-' + (this.searchFrom.date2.getMonth() + 1) + '-' + this.searchFrom.date2.getDate()
    //   }
    // },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.pageSize = val
      this.getAll()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.pageNo = val
      this.getAll()
    },
    // handleClick(val) {
    //   // this.showEdit = true
    //   console.log(val, '点击行的信息')
    //   if (val.isOK == '有效') {
    //     val.status = '-1'
    //   }
    //   if (val.isOK == '无效') {
    //     val.status = '1'
    //   }
    //   postRequest('/product/update/', val).then(res =>{
    //     console.log(res)
    //     this.$message({
    //       message: '状态修改成功',
    //       type: 'success'
    //     })
    //     let data = {}
    //     this.getAll(data)
    //   })
    // },
    lookFor(val) {
      this.showEdit = true
      this.info = val
      this.onlyLook = true
    },
    // 查询产品
    getAll(no) {
      this.loading = true
      this.disabledSearch = true
      let data = {
        productName: this.searchFrom.organizationName ? this.searchFrom.organizationName : '',
        catalogCode: this.searchFrom.organizationCode ? this.searchFrom.organizationCode : '',
        startDate: this.searchFrom.date1 ? this.searchFrom.date1 + ' 00:00:00' : '',
        endDate: this.searchFrom.date2 ? this.searchFrom.date2 + ' 23:59:59' : '',
        deptId: this.searchFrom.state ? this.searchFrom.state : '',
        pageNo: no ? no : this.pageNo,
        pageSize: this.pageSize,
      }
      postRequest('/product/queryProductPage/', data)
        .then(res => {
          this.disabledSearch = false
          console.log(res, '查询所有产品结果')
          this.loading = false
          this.tableData = res.data
          this.total = res.count
          res.data.map(item => {
            item.startTime = item.startDate.substring(0, 10)
            // this.companyList.map(i => {
            //   if (i.companyCode == item.deptId) {
            //     item.name = i.companyName
            //   }
            // })
            // item.productRigthVoList.map(j => {
            //   this.rightsList.map(x => {
            //     if (x.dictionaryCode == j.rightsId) {
            //       j.rightsName = x.dictionaryName
            //     }
            //   })
            // })
          })
        })
        .catch(err => {
          this.loading = false
          this.disabledSearch = false
        })
    },
    updateProduct() {
      console.log('点击修改')
      if (this.multipleSelection.length < 1) {
        this.$message({
          message: '请选择需要修改的产品',
          type: 'warning',
        })
      } else if (this.multipleSelection.length > 1) {
        this.$message({
          message: '只能选择一项进行修改',
          type: 'warning',
        })
      } else if (this.multipleSelection.length == 1) {
        // if (this.multipleSelection[0].updateOrDelType == '0') {
        //   this.$message({
        //     message: '此项产品已有订单,不可修改',
        //     type: 'warning'
        //   })
        //   return false
        // } else {
        this.showEdit = true
        this.info = this.multipleSelection[0]
        this.onlyLook = false
        // }
      }
    },
    // 删除
    deleteProduct() {
      console.log(this.multipleSelection, 'this.multipleSelection')
      if (this.multipleSelection.length <= 0) {
        this.$message({
          message: '请选择需要删除的产品',
          type: 'warning',
        })
        return
      }
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          // this.multipleSelection.map(item => {
          //   let data = {
          //     id: item.id,
          //   }
          //   postRequest('/product/delete/', data).then(res=>{
          //     console.log(res, '删除')
          //     this.$message({
          //       message: '删除成功',
          //       type: 'success'
          //     })
          //     this.getAll()
          //   })
          // })
          this.multipleSelection.some(item => {
            if (item.updateOrDelType == '0') {
              // this.$message({
              //   message: '此项产品已有订单, 不可修改',
              //   type: 'warning'
              // })
              return false
            }
          })
          postRequest('/product/delete/', this.multipleSelection).then(res => {
            console.log(res, '删除')
            this.$message({
              message: '删除成功',
              type: 'success',
            })
            this.getAll()
          })
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: '已取消删除',
          })
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.organ-management-page {
  background: transparent;
}
// .el-form-item--small.el-form-item {
//   margin-right: 3px !important;
// }
.Selectnum {
  padding: 6px 30px;
  border: 1px solid #bfcbf4;
  font-size: 12px;
  color: #666;
  border-radius: 5px;
  margin-bottom: 10px;
}
</style>
